import { Config } from 'core/common/contexts';
import { config } from 'core/config';

export const appnebulaConfig: Config = {
  ...config,
  apiUrl: 'https://stage-api-appnebula.asknebula.com/api/v1/',
  mainAppUrl: 'https://stage-appnebula.asknebula.com/',
  rootDomain: 'nebula-destiny.com',
  domainUrl: 'nebula-destiny.com',
};
