/* eslint-disable max-len */
import { config } from 'core/config';

export const es = {
  translation: {
    'app-subscription-4-7d-trial-per-day': {
      progressBarTitle: 'Tu perfil',
      buttonNext: 'Siguiente',
      gender: {
        title: 'Conócete a ti mismo y Mejora tus Relaciones con la Astrología',
        subtitle: 'Evaluación Personal de 1 Minuto',
        signIn: '¿Ya tiene una cuenta? Iniciar sesión',
        address: `${config.companyName}, Athalassas, 62, ENTRESUELO, Strovolos, 2012, Nicosia, Chipre`,
        actionTitle: 'Selecciona tu género:',
        answers: {
          male: 'hombre',
          female: 'mujer',
        },
      },
      mentionedIn: {
        title: '42+ millones de personas',
        subtitle: 'ya usan Nebula',
        testimonial:
          'Nebula tiene como objetivo iluminar conexiones interpersonales utilizando signos astrológicos',
        mentionedInText: 'MENCIONADO EN',
        next: 'Continuar',
      },
      flowChoice: {
        title: 'Para que podamos conocerte mejor, cuéntanos sobre tu estado de relación.',
        single: 'Soltero/a',
        relationship: 'En una relación',
        married: 'Casado/a',
        complicated: 'Complicado',
        unsure: 'No estoy seguro/a / Otro',
      },
      relationshipGoalSingle: {
        title: '¿Cuál es tu objetivo?',
        answers: {
          perfectPartner: 'Encontrar mi pareja perfecta',
          understandBetter: 'Conocerme mejor a mi mismo/a',
          getMarried: 'Para casarse',
          happiness: 'Alcanzar la felicidad',
          growth: 'Crecimiento personal',
          compatibility: 'Compruebe la compatibilidad',
          all: 'Todo lo anterior',
        },
        store: {
          perfectPartner: 'encuentra tu pareja perfecta',
          understandBetter: 'entiéndete mejor a ti mismo/a',
          loadingBoth: 'encuentra tu pareja perfecta y conócete mejor a ti mismo/a',
          getMarried: 'todo lo anteriorencuentra el amor para tu matrimonio',
          happiness: 'lograr una vida feliz',
          growth: 'crecer personalmente',
          compatibility: 'compruebe la compatibilidad',
          findLoveAndUnderstandBetter: 'encuentra el amor y compréndete mejor a ti mismo',
        },
      },
      goalSetup: {
        title: '¡Excelente! ¡Acabas de fijar tu primer objetivo!',
        text: 'Sigamos para poder conocerte mejor.',
        next: 'Siguiente',
        back: 'Volver',
      },
      parent: {
        title: '¿Tienes hijos?',
        answers: { yes: 'Si', no: 'No' },
      },
      astrologyKnowledge: {
        title: '¿Cómo te consideras en tu conocimiento en astrología?',
        subtitle: 'Para que podamos adaptar las revelaciones a tu nivel de conocimiento',
        answers: { expert: 'Un experto', curious: 'Curioso', beginner: 'Un principiante' },
      },
      whatIs: {
        title: '¿Qué es <b>la astrología</b> <b>hiperpersonalizada,</b> de todos modos?',
        text:
          'La astrología personalizada se basa en el entendimiento de que todos tienen un plano astrológico único. ' +
          'La posición de las estrellas en el cielo en tu fecha de nacimiento, el lugar y la hora en que naciste, ' +
          'son cosas únicas y solo ocurren una vez por vida. ¡Eres más que tu signo zodiacal!' +
          '</br>' +
          '</br>' +
          '<b>¿Entonces, cómo funciona?</b>' +
          '</br>' +
          'Analizamos cientos de puntos de datos para crear tu plano astrológico único. ' +
          'Esto se combina con la IA para personalizar tus observaciones astrológicas, según tus respuestas. ' +
          'Vamos a cambiar tu relación con la astrología.',
        next: 'Siguiente',
      },
      date: {
        title: '¿Cuál es tu fecha de nacimiento?',
        next: 'Siguiente',
      },
      singleZodiacInfo: {
        next: 'Siguiente',
      },
      birthTime: {
        title: '¿Sabes la hora de tu nacimiento?',
        answers: { yes: 'Si', no: 'No' },
      },
      time: {
        title: '¿A qué hora naciste?',
        text:
          'Usamos datos de la NASA para identificar la posición exacta de los planetas en el cielo al momento ' +
          'de tu nacimiento.',
        label: '',
        next: 'Siguiente',
      },
      noTime: {
        title:
          '<strong>¡No hay problema! Igual puedes encontrar muchas observaciones excelentes sin saber tu' +
          ' hora exacta de nacimiento.</strong>',
        text: 'Consejo: Si te enteras más tarde, puedes ajustarlo en tu configuración de perfil.',
        next: 'Siguiente',
        back: 'Volver',
      },
      place: {
        title: '¿Dónde naciste?',
        text: 'Esto determina la zona horaria del lugar de tu nacimiento.',
        next: 'Siguiente',
        cancel: 'Cancelar',
      },
      perfectPartner: {
        titleStart: '¡Hemos ayudado ',
        titleMiddle: ' a otros/as ',
        titleEnd: ' a encontrar a su pareja perfecta y estamos ansiosos por ayudarte también!',
        subtitle: '*para Febrero de 2023',
        list: ['Conectando con la base de datos...'],
      },
      understandBetter: {
        titleStart: '¡Hemos ayudado ',
        titleMiddle: ' a otros/as ',
        titleEnd: ' a comprenderse mejor a sí mismos, y estamos ansiosos por ayudarte también!',
        subtitle: '*para Febrero de 2023',
        list: ['Conectando con la base de datos...'],
      },
      loadingBoth: {
        titleStart: '¡Hemos ayudado ',
        titleMiddle: ' a otros/as ',
        titleEnd:
          ' a comprenderse mejor a sí mismos y encontrar su pareja perfecta, y estamos ansiosos por ayudarte ' +
          'también!',
        subtitle: '*para Febrero de 2023',
        list: ['Conectando con la base de datos...'],
      },
      singleProblem: {
        answers: {
          things: 'Estaba descontento con cómo las cosas iban en mi relación',
          parts: 'Estaba descontento con partes de mi relación, pero algunas cosas funcionaban',
          happy: 'En general, estaba contento con mi relación',
          never: 'Nunca estuve en una relación',
        },
        young: 'menor de 20 años',
        old: 'mayor de 60 años',
        adult: 'en sus',
        male: 'hombres',
        female: 'mujeres',
        parent: 'con hijos',
        title: {
          singleStart: 'Soltero/a {{translate gender}} ',
          singleEnd:
            'necesita un enfoque un poco diferente para encontrar a su pareja perfecta. Pero primero, ¿cómo te sentiste en tu última relación?',
          relationship:
            'necesita un enfoque un poco ' +
            'diferente para mejorar su relación. ¿Qué afirmación te describe mejor?',
        },
      },
      traitsWorks: {
        title:
          'Para averiguar qué funciona para nosotros, a veces necesitamos entender qué es lo que no. ' +
          'Te daremos observaciones sobre cómo construir una relación feliz y duradera con tu nueva pareja.',
        text: 'Ahora, ¡Necesitamos información sobre tus <b>Rasgos de Personalidad</b> para profundizar!',
        next: 'Siguiente',
        back: 'Volver',
      },
      traitsMoveOn: {
        title:
          '¡A veces eso sucede y necesitas seguir adelante! Te daremos observaciones sobre cómo construir una' +
          ' relación duradera con tu próxima pareja.',
        text: '¡Ahora, necesitamos información sobre tus <b>Rasgos de Personalidad</b> para profundizar!',
        next: 'Siguiente',
        back: 'Volver',
      },
      traitsCompatible: {
        title:
          'Bueno, ¡se vienen tiempos emocionantes! Te daremos información sobre tu pareja más compatible.',
        text: '¡Ahora, necesitamos información sobre tus <b>Rasgos de Personalidad</b> para profundizar!',
        next: 'Siguiente',
        back: 'Volver',
      },
      relateToStatement: {
        title: '¿Te identificas con la siguiente afirmación?',
        subtitle: '<strong>“Realmente me disgusta estar solo, aunque odie admitirlo.”</strong>',
        answers: { yes: 'Si', no: 'No' },
      },
      notice: {
        title: '¿Tiendes a fijarte más en lo que está mal que en lo que está bien?',
        answers: {
          veryFrequently: 'Muy frecuentemente',
          frequently: 'Frecuentemente',
          occasionally: 'Ocasionalmente',
          rarely: 'Raramente',
          never: 'Nunca',
        },
      },
      sensitive: {
        title: '¿Eres sensible a las críticas?',
        answers: { yes: 'Si', sometimes: 'A veces', rarely: 'Raramente', notAtAll: 'Para nada' },
      },
      tendToOverthink: {
        title: ' ¿Tiendes a pensar demasiado?',
        answers: { yes: 'Si', no: 'No' },
      },
      mostImportant: {
        title: '¿Qué es lo más importante para ti?',
        answers: {
          success: 'Éxito',
          romance: 'Romance',
          stability: 'Estabilidad',
          freedom: 'Libertad',
          happiness: 'Felicidad',
          health: 'Salud',
          all: 'Todo lo anterior',
        },
      },
      emotionalControl: {
        title: '¿Es el control emocional difícil para ti?',
        answers: { yes: 'Si', sometimes: 'A veces', rarely: 'Raramente', notAtAll: 'Para nada' },
      },
      notAlone: {
        title: `No estás solo.<br/>A muchas personas <span>{{translate zodiac}}</span> les cuesta controlar las emociones debido a los rasgos de su signo zodiacal. ¡No te preocupes, sabemos cómo dar respuesta a eso`,
        next: 'Siguiente',
        back: 'Volver',
      },
      allRight: {
        title: `¡Muy bien!<br/> Mantienes tus emociones bajo control, <span>{{translate zodiac}}</span>, pero a veces la gente puede querer que muestres más pasión. ¡Podemos darte unas observaciones sobre eso!`,
        next: 'Siguiente',
        back: 'Volver',
      },
      attitude: {
        title: '¿Amas tu trabajo?',
        answers: {
          doWhatLove: '¡Sí! Hago algo que amo',
          notWorking: 'No estoy trabajando',
          hateJob: 'Odio mi trabajo',
          paysBills: 'Está bien, paga las cuentas',
          underPaid: 'Me pagan mal',
          growing: 'He dejado de crecer profesionalmente',
        },
      },
      want: {
        title: '¿Siempre sabes exactamente lo que quieres?',
        answers: { yes: 'Si', no: 'No', notSure: 'No estoy seguro/a' },
      },
      relaxing: {
        title: '¿Tienes problemas para relajarte?',
        answers: { notAtAll: 'Para nada', yes: 'Si', sometimes: 'A veces', rarely: 'Raramente' },
      },
      almostThere: {
        title:
          '¡Casi terminamos!' +
          '<br/>' +
          'Ahora adaptemos tu plan' +
          '<br />' +
          ' comprendiendo el <b>Patrón de Relación</b>.',
        text:
          'Por favor, tómate tu tiempo al responder. Cada respuesta se utiliza para elaborar tu plan ' +
          'de orientación.',
        next: 'Siguiente',
        back: 'Volver',
      },
      priority: {
        title: '¿Estás de acuerdo con la siguiente afirmación?',
        subtitle: '<strong>“Cuando estoy en una relación, el sexo es una prioridad.“</strong>',
        answers: {
          stronglyAgree: 'Totalmente de acuerdo',
          agree: 'De acuerdo',
          neutral: 'Neutral',
          disagree: 'Desacuerdo',
          stronglyDisagree: 'Totalmente en desacuerdo',
        },
      },
      comfortable: {
        title: '¿Estás de acuerdo con la siguiente afirmación?',
        subtitle: '<strong>“Me siento cómodo/a con personas que apenas conozco.“</strong>',
        answers: {
          notAtAll: 'Para nada',
          yes: 'Si',
          sometimes: 'A veces',
          rarely: 'Raramente',
          // Experiment quiz visual element
          // TODO: Remove unnecessary answers after end test
          stronglyAgree: 'Totalmente de acuerdo',
          agree: 'De acuerdo',
          neutral: 'Neutral',
          disagree: 'Desacuerdo',
          stronglyDisagree: 'Totalmente en desacuerdo',
        },
      },
      goodEnough: {
        title: ' ¿Te preocupas a menudo por no ser lo suficientemente bueno?',
        answers: { notAtAll: 'Para nada', yes: 'Si', sometimes: 'A veces', rarely: 'Raramente' },
      },
      angry: {
        title: '¿Te enojas o irritas con facilidad?',
        answers: { notAtAll: 'Para nada', yes: 'Si', sometimes: 'A veces', rarely: 'Raramente' },
      },
      innerSelf: {
        title: '¿Compartes tu interior con las personas que te gustan?',
        answers: { yes: 'Si', no: 'No', notSure: 'No estoy seguro' },
      },
      aboutPeople: {
        title: '¿Recuerdas pequeños detalles de las personas que son importantes para ti?',
        answers: { notAtAll: 'Para nada', yes: 'Si', sometimes: 'A veces', rarely: 'Raramente' },
      },
      idealDate: {
        title: '¿Cuál es tu cita ideal?',
        answers: {
          restaurant: 'Una cena en un restaurante elegante',
          nature: 'Una caminata en la naturaleza',
          talking: 'Hablar bajo las estrellas',
          walk: 'Una caminata en el parque',
          skydiving: 'Paracaidismo',
        },
      },
      futurePartner: {
        title:
          '¿Describirías a tu futura pareja como una persona orientada a los detalles o alguien que mira ' +
          'el panorama general?',
        answers: {
          detailOriented: 'Orientada a los detalles',
          bigPicture: 'Que mira el panorama general',
          both: 'Un poco de ambas',
        },
      },
      idealPartner: {
        title: '¿Es tu pareja ideal introvertida o extrovertida?',
        answers: { introvert: 'Introvertida', extrovert: 'Extrovertida', both: 'Un poco de ambas' },
      },
      relationshipGoal: {
        title: 'Cuando piensas en tus objetivos de relación, ¿te sientes...?',
        answers: {
          rightPlace: '¡Optimista! Son totalmente factibles, con cierta ayuda.',
          thing: 'Precavido/a. He tenido dificultades antes, pero tengo esperanzas.',
          totallyNormal: 'Me siento un poco ansioso/a, de verdad.',
        },
      },
      rightPlace: {
        title: 'Has venido al lugar correcto,<br/><span>{{translate zodiac}}</span>!',
        subtitle:
          'Saber que tus metas son factibles te pone en buen camino. ' +
          'Sigamos adelante para poder elaborar un plan de orientación basado en tu plano astrológico.',
        next: 'Siguiente',
        back: 'Volver',
      },
      thing: {
        title: `<span>{{translate zodiac}}</span>, esto es lo que sucede…<br/> ¡Puedes hacerlo!`,
        subtitle:
          'Comenzaremos con pequeñas observaciones para que no se sienta demasiado abrumador. Sigamos ' +
          'adelante y elaboraremos un plan de orientación basado en tu plano astrológico.',
        next: 'Siguiente',
        back: 'Volver',
      },
      totallyNormal: {
        title: `Es totalmente normal sentirte nervioso,<br/><span>{{translate zodiac}}</span>.`,
        subtitle:
          'Pero sabemos que puedes hacerlo. ' +
          'Sigamos adelante para poder elaborar un plan de orientación según tu plano astrológico.',
        next: 'Siguiente',
        back: 'Volver',
      },
      communicate: {
        title: '¿Te resulta fácil comunicarte con otras personas?',
        answers: { yes: 'Sí', no: 'No' },
      },
      decisionsSingle: {
        title: '¿Tomas decisiones con la cabeza o el corazón?',
        answers: { heart: 'Corazón', head: 'Cabeza', both: 'Ambos' },
      },
      withHeartSingle: {
        title: `Según nuestros datos, el 49% de las personas <span>{{translate zodiac}}</span> también toman decisiones con el corazón. Pero no te preocupes, lo consideraremos al elaborar tu plan de orientación.`,
        next: 'Siguiente',
        back: 'Volver',
      },
      withHeadSingle: {
        title: `Según nuestros datos, el 39% de las personas <span>{{translate zodiac}}</span> también toman decisiones con la cabeza. Pero no te preocupes, lo consideraremos al elaborar tu plan de orientación.`,
        next: 'Siguiente',
        back: 'Volver',
      },
      bothSingle: {
        title: `¡Maravilloso!<br/>Según nuestros datos, solo el 17% superior de las personas <span>{{translate zodiac}}</span> toman decisiones con el corazón y la cabeza. Usar ambos en igual medida es la clave para sentirte en armonía en tus relaciones.`,
        next: 'Siguiente',
        back: 'Volver',
      },
      aboutUsSingle: {
        title: '¿Сómo te enteraste de nosotros?',
        answers: {
          poster: 'Cartel o Valla Publicitaria',
          friend: 'Amigo o Familia',
          instagram: 'Instagram',
          mail: 'Correo Directo o Prospecto',
          streaming: 'TV En Línea o Streaming',
          tv: 'TV',
          radio: 'Radio',
          search: 'Motor de búsqueda (Google, Bing, etc.)',
          newspaper: 'Periódico o Revista',
          facebook: 'Facebook',
          review: 'Blog o Reseña en una Página',
          podcast: 'Podcast',
          influencer: 'Influencer',
          youtube: 'Youtube',
          pinterest: 'Pinterest',
          other: 'Otro',
        },
      },
      relationshipGoalInARelationship: {
        title: '¿Cuál es tu objetivo?',
        answers: {
          fixRelationship: 'Solucionar problemas de relación',
          increaseSatisfaction: 'Estar más satisfecho con mi relación',
          buildStrongMarriage: 'Construir un matrimonio sólido',
          checkCompatibility: 'Compruebe la compatibilidad',
          all: 'Todo lo anterior',
        },
        store: {
          fixRelationship: 'Solucionar problemas de relación',
          improveRelationship: 'mejore su relación',
          buildStrongMarriage: 'construir un matrimonio sólido',
          increaseSatisfaction: 'Estar más satisfecho con mi relación',
          checkCompatibility: 'compruebe la compatibilidad',
          both: 'solucionar problemas de relación y estar más satisfecho con mi relación.',
        },
      },
      loadingInRelationship: {
        titleStart: 'Hemos ayudado ',
        titleMiddle: ' con su Sol en ',
        titleEnd: ' a mejorar su relación ¡y estamos ansiosos de ayudarte también!',
        titleEndShort: 'y estamos deseando ayudarte a ti también.',
        subtitle: '*para Febrero de 2023',
        goal: {
          perfectPartner: 'para encontrar a su pareja perfecta',
          understandBetter: 'comprenderse mejor a sí mismos',
          growth: 'crecer personalmente',
          getMarried: 'casarse',
          achieveHappiness: 'alcanzar la felicidad',
          checkCompatibility: 'compruebe la compatibilidad',
          increaseSatisfaction: 'estar más satisfecho con mi relación',
          fixRelationship: 'solucionar problemas de relación',
          buildStrongMarriage: 'construir un matrimonio sólido',
          allAboveSingle: 'con orientación personal',
          allAboveRelationship: 'con orientación para las relaciones',
        },
        list: ['Conectando a base de datos...'],
      },
      relationshipProblem: {
        answers: {
          absolutelyUnhappy: 'No estoy muy contento con cómo van las cosas en mi relación',
          particularyHappy:
            'No estoy contento con partes de mi relación, pero algunas cosas funcionan bien',
          generallyHappy: 'En general estoy feliz en mi relación',
        },
      },
      worksForUs: {
        title:
          '¡Te tenemos cubierto! Comenzaremos con pequeñas, personalizadas ' +
          'revelaciones sobre tus rasgos de personalidad y los de tu pareja.',
        text:
          'Ahora, necesitamos algo de información sobre <br/><b>El Perfil de tu Pareja</b> para crear ' +
          'el plano de sinastría astrológica con tu pareja.',
        next: 'Siguiente',
        back: 'Volver',
      },
      moveOn: {
        title:
          '¡Probablemente estás haciéndolo mejor de lo que crees! ' +
          'Te ayudaremos a identificar cómo mejorar y mantener la relación',
        text:
          'Ahora, necesitamos algo de información sobre <br/><b>El Perfil de tu Pareja</b> para crear ' +
          'el plano de sinastría astrológica con tu pareja.',
        next: 'Siguiente',
        back: 'Volver',
      },
      mostCompatible: {
        title: '¡Maravilloso!<br/>Averigüemos qué funciona (y qué no) y partamos de ahí.',
        text:
          'Ahora, necesitamos algo de información sobre <br/><b>El Perfil de tu Pareja</b> para crear ' +
          'el plano de sinastría astrológica con tu pareja.',
        next: 'Siguiente',
        back: 'Volver',
      },
      partnerGender: {
        title: '¿Cuál es el género de tu pareja?',
        answers: {
          male: 'Hombre',
          female: 'Mujer',
        },
      },
      partnerDate: {
        title: '¿Cuál es la fecha de nacimiento de tu pareja?',
        text:
          'Su fecha de nacimiento es información esencial para construir el plano ' +
          'astrológico personal de tu pareja.',
        // TODO: refactor after close test quiz with visual elements
        experimentText: 'Esto pone de relieve la dualidad de tus energías masculina y femenina.',
        next: 'Siguiente',
      },
      relationshipZodiacInfo: {
        next: 'Siguiente',
        captionTitle: '¿Qué tan compatibles son?',
        captionDescription: 'Vamos más allá y descubrámoslo.',
      },
      partnerBirthTime: {
        title: '¿Sabes a qué hora nació tu pareja?',
        answers: { yes: 'Sí', no: 'No' },
      },
      partnerTime: {
        title: '¿A qué hora nació tu pareja?',
        text:
          'Usamos datos de la NASA para identificar la' +
          ' posición exacta de los planetas en el cielo en el momento del nacimiento de tu pareja.',
        label: '',
        next: 'Siguiente',
      },
      partnerNoTime: {
        title:
          '<strong>No se preocupe. Sin saber la hora exacta de nacimiento de tu pareja, ' +
          'podrás encontrar muchas cosas interesantes.</strong>',
        text: 'Consejo: Si lo averiguas más tarde, puedes ajustarlo en el perfil de tu pareja.',
        next: 'Siguiente',
        back: 'Volver',
      },
      partnerPlace: {
        title: '¿Dónde nació tu pareja?',
        text: 'Si no sabes el lugar exacto de nacimiento, escribe el país de nacimiento.',
        next: 'Siguiente',
        cancel: 'Cancelar',
      },
      relationshipAlmostThere: {
        title:
          '¡Ya terminamos!' +
          '<br/>' +
          'Ahora comencemos a adaptar tu plan comprendiendo tus ' +
          '<b>Patrones de Relación y Personalidad</b>.',
        text:
          'Por favor tómate tu tiempo al responder. ' +
          'Cada respuesta se utiliza para elaborar el plan de orientación para ti y tu pareja.',
        next: 'Siguiente',
        back: 'Volver',
      },
      issueTogether: {
        title: '¿Estás de acuerdo con la siguiente afirmación?',
        subtitle: '<strong>“Mi pareja y yo podemos hablar de cualquier tema juntos“</strong>',
        answers: {
          stronglyAgree: 'Totalmente de acuerdo',
          agree: 'De acuerdo',
          neutral: 'Neutral',
          disagree: 'En desacuerdo',
          stronglyDisagree: 'Muy en desacuerdo',
        },
      },
      currentlyAffecting: {
        title: '¿Alguno de estos factores está afectando actualmente tu relación?',
        answers: {
          infidelity: 'Infidelidad',
          depression: 'Depresión',
          lowSexualDesire: 'Bajo deseo sexual',
          poorBodyImage: 'Mala imagen corporal',
          postpartumDepression: 'Depresión post-parto',
          anxiety: 'Ansiedad',
          parentingIssues: 'Problemas de crianza',
          blendedFamilyIssues: 'Problemas de la familia mixta',
          divorceIsOnTheTable: 'El divorcio está sobre la mesa',
          noneOfThese: 'Ninguno de los anteriores',
        },
        next: 'Siguiente',
      },
      partnerPriority: {
        title: '¿Estás de acuerdo con la siguiente afirmación?',
        subtitle:
          '<strong>“Mi pareja y yo hacemos del sexo una prioridad en nuestra relación”</strong>',
        answers: {
          stronglyAgree: 'Totalmente de acuerdo',
          agree: 'De acuerdo',
          neutral: 'Neutral',
          disagree: 'En desacuerdo',
          stronglyDisagree: 'Muy en desacuerdo',
        },
      },
      satisfied: {
        title: '¿Estás satisfecho/a con la forma en que te comunicas con tu pareja?',
        answers: { yes: 'Sí', no: 'No' },
      },
      satisfiedYes: {
        title: `¡Bien hecho!<br/>Según nuestros datos, solo al 30% de <span>{{translate zodiac}}</span> les resulta fácil comunicarse con su <span>{{translate partnerZodiac}}</span> pareja`,
        next: 'Siguiente',
        back: 'Volver',
      },
      satisfiedNo: {
        title: `No estás solo.<br/>Según nuestros datos, al 74% de <span>{{translate zodiac}}</span> les resulta difícil comunicarse con su <span>{{translate partnerZodiac}}</span> pareja.<br/> Podemos ayudarte a mejorar esto.`,
        titleFirstLine: 'No estás solo.',
        titleSecondLine: 'Según nuestros datos, al 74% de ',
        titleMiddle: ' les resulta difícil comunicarse con su ',
        titleEnd: ' pareja.',
        titleLastLine: 'Podemos ayudarte a mejorar esto.',
        next: 'Siguiente',
        back: 'Volver',
      },
      emotionalConnection: {
        title: '¿Estás de acuerdo con la siguiente afirmación?',
        subtitle:
          '<strong>“Fortalecer nuestra conexión emocional es una prioridad tanto para mi pareja como para mí”</strong>',
        answers: {
          stronglyAgree: 'Totalmente de acuerdo',
          agree: 'De acuerdo',
          neutral: 'Neutral',
          disagree: 'En desacuerdo',
          stronglyDisagree: 'Muy en desacuerdo',
        },
      },
      strengthen: {
        title:
          'Nebula ayuda a <b> fortalecer tu ' +
          '</br>' +
          'relación</b> y <b>mejorar tu ' +
          '</br>' +
          'vida amorosa</b>*',
        tooltip: 'Encuesta con 1126 encuestados, realizada en Junio de 2021',
        text: '* El 75% de nuestros usuarios informaron que la astrología les ayudó a mejorar su relación',
        next: 'Siguiente',
      },
      bigPicture: {
        title:
          '¿Describirías a tu pareja como una persona orientada a los detalles o a la visión general?',
        answers: {
          detailOriented: 'Orientada a los detalles',
          bigPicture: 'A la visión general',
          both: 'Un poco de ambas',
        },
      },
      introvertOrExtravert: {
        title: '¿Es tu pareja introvertida o extrovertida?',
        answers: { introvert: 'Introvertida', extrovert: 'Extrovertida', both: 'Un poco de ambas' },
      },
      irritated: {
        title: '¿Tu pareja se enfada o se irrita con facilidad?',
        answers: { yes: 'Sí', sometimes: 'A veces', rarely: 'Raramente', notAtAll: 'Para nada' },
      },
      conflict: {
        title: '¿Estás satisfecho con la forma en que tú y tu pareja enfrentan el conflicto?',
        answers: {
          honest: 'Sí, prefiero ser honesto y directo',
          situation: 'Depende de la situación y de la persona',
          hurt: 'No, no quiero hacer daño a otra persona ni que me hagan daño',
          nervous: 'No, me pone nervioso',
        },
      },
      aboutGoals: {
        title: 'Cuando piensas en los objetivos de tu relación, ¿te sientes...?',
        answers: {
          partnerRightPlace: '¡Optimista! Son totalmente factibles, con un poco de orientación',
          partnerThing: 'Precavido/a. He tenido dificultades antes, pero tengo esperanzas',
          partnerTotallyNormal: 'Me siento un poco ansioso/a, de verdad',
          notSure: 'No estoy seguro / No lo sé',
        },
      },
      partnerRightPlace: {
        title: `Has venido al lugar correcto,<br/><span>{{translate zodiac}}</span>!`,
        subtitle:
          'Saber que tu objetivo es factible te da ventaja. ' +
          'Sigamos para poder elaborar un plan de orientación basado en tu plano astrológico.',
        next: 'Siguiente',
        back: 'Volver',
      },
      partnerThing: {
        title: `<span>{{translate zodiac}}</span>, este es la cosa...<br/>¡Puedes hacerlo!`,
        subtitle:
          'Empezaremos con pequeñas revelaciones para que no se sienta muy abrumador. Sigamos' +
          'adelante y elaboremos un plan de orientación según tu plano astrológico.',
        next: 'Siguiente',
        back: 'Volver',
      },
      partnerTotallyNormal: {
        title: `Es totalmente normal sentirte nervioso,<br/><span>{{translate zodiac}}</span>.`,
        subtitle:
          'Pero sabemos que puedes hacer esto. ' +
          'Sigamos adelante para poder construir un plan de orientación basado en tu plano astrológico.',
        next: 'Siguiente',
        back: 'Volver',
      },
      appreciated: {
        title: '¿Estás de acuerdo con la siguiente afirmación?',
        subtitle: '<strong>“Mi pareja me hace sentir muy apreciado/a.”</strong>',
        answers: {
          stronglyAgree: 'Totalmente de acuerdo',
          agree: 'De acuerdo',
          neutral: 'Neutral',
          disagree: 'Desacuerdo',
          stronglyDisagree: 'Totalmente en desacuerdo',
        },
      },
      decisions: {
        title: '¿Tomas decisiones con tu cabeza o con tu corazón?',
        answers: { heart: 'Corazón', head: 'Cabeza', both: 'Ambos' },
      },
      withHeart: {
        title: `Según nuestros datos, el 49% de las personas <span>{{translate zodiac}}</span> también toman decisiones con el corazón. Pero no te preocupes, lo consideraremos al elaborar tu plan de orientación.`,
        next: 'Siguiente',
        back: 'Volver',
      },
      withHead: {
        title: `Según nuestros datos, el 39% de las personas <span>{{translate zodiac}}</span> también toman decisiones con la cabeza. Pero no te preocupes, lo consideraremos al crear tu plan de orientación.`,
        next: 'Siguiente',
        back: 'Volver',
      },
      both: {
        title: `¡Maravilloso!<br/>Según nuestros datos, solo el 17% superior de las personas <span>{{translate zodiac}}</span> toma decisiones con el corazón y la cabeza. Usar ambos en igual medida es la clave para sentirte en armonía en tus relaciones.`,
        next: 'Siguiente',
        back: 'Volver',
      },
      aboutUs: {
        title: '¿Сómo te enteraste de nosotros?',
        answers: {
          poster: 'Cartel o Valla Publicitaria',
          friend: 'Amigo o Familia',
          instagram: 'Instagram',
          mail: 'Correo Directo o Prospecto',
          streaming: 'TV En Línea o Streaming',
          tv: 'TV',
          radio: 'Radio',
          search: 'Motor de búsqueda (Google, Bing, etc.)',
          newspaper: 'Periódico o Revista',
          facebook: 'Facebook',
          review: 'Blog o Reseña en una Página',
          podcast: 'Podcast',
          influencer: 'Influencer',
          youtube: 'Youtube',
          pinterest: 'Pinterest',
          other: 'Otro',
        },
      },
      loading: {
        initialText: 'Analizando tu perfil...',
        steps: {
          position: 'Identificando las posiciones planetarias en tu nacimiento...',
          blueprint: 'Creando tu plano astrológico...',
          profile: 'Evaluando tu perfil de personalidad...',
          strength: 'Identificando tus fortalezas y debilidades...',
          compatibility: 'Analizando tu compatibilidad...',
          relationship: 'Analizando tus necesidades de relación...',
          guidance: 'Elaborando el mejor plan de orientación...',
          predicting: 'Prediciendo resultados futuros...',
          alone: '¿Disfrutas el tiempo que pasas solo/a?',
          adventurous: '¿Eres una persona aventurera?',
          remedies: '¿Alguna vez has probado algún remedio/ritual?',
        },
        question: { yes: 'Si', no: 'No' },
        text:
          '¡Espera un momento! Estamos elaborando el perfecto plan de orientación basado en tu plano astrológico' +
          'único y millones de puntos de datos de usuarios exitosos de Nebula.',
        list: [
          'Diseñando tus instantáneas astronómicas únicas',
          'Analizando tus cartas natales',
          'Comparando tus lecturas de astrología',
          'Eligiendo las mejores revelaciones',
          'Preparando tu informe de compatibilidad',
        ],
      },
      email: {
        title: 'Ingresa tu email para ver cómo puedes',
        nebula: 'con Nebula',
        text:
          '*Nebula no comparte ninguna información personal. ' +
          'Te enviaremos una copia de tu plan por correo electrónico para un mejor acceso.',
        label:
          'Me gustaría recibir mi informe y ofertas personalizadas' +
          ' & novedades de Nebula a través de este email.',
        error: 'Ingresa un correo electrónico válido',
        button: 'Continuar',
        placeholder: 'Tu correo electrónico',
      },
      emailConfirm: {
        title:
          'Obtenga acceso a <b>su informe exclusivo</b>, ' +
          'ofertas especiales, actualizaciones, consejos sobre astrología y relaciones, recetas y regalos gratis.',
        subtitle:
          'Entérate de todo Confirme la recepción de correos electrónicos para no perderse nada',
        button: 'Сonfirmar',
        skip: 'Sé todo sobre astrología y relaciones',
      },
      onboarding: {
        list: [
          'Bienvenido a Nebula',
          'Según tus respuestas',
          'Hemos creado tu plano astrológico y tu plan de orientación',
          'Para ayudarte a comprenderte mejor a ti mismo/a',
          'Y para mejorar tu relación, de una vez por todas.',
          'Empecemos.',
        ],
        listWithGoal: [
          'Bienvenido a Nebula',
          'Según tus respuestas',
          'Hemos creado tu plano astrológico y tu plan de orientación',
          'Para ayudarte a {{goal}}',
          'Y para mejorar tu relación, de una vez por todas.',
          'Empecemos.',
        ],
      },
      salesScreen: {
        title: 'Su Claridad Personalizada & ¡Lectura del Amor está lista!',
        fixedHeader: {
          title: `Descuento expirado`,
          minutes: 'min',
          seconds: 'sec',
          button: 'obtener mi lectura',
        },
        userDetails: {
          youImageText: 'Usted',
          partnerImageText: 'Socio',
          zodiac: 'Signo del zodiaco',
          gender: 'Género',
          dateOfBirth: 'Fecha de nacimiento',
          placeOfBirth: 'Lugar de nacimiento',
          female: 'Mujer',
          male: 'Hombre',
        },
        sectionGoal: {
          title: 'El objetivo a alcanzar',
          'Find my perfect partner': 'Encontrar mi pareja perfecta',
          'Understand myself better': 'Conocerme mejor a mi mismo/a',
          'To get married': 'Para casarse',
          'Achieve happiness': 'Alcanzar la felicidad',
          'Personal growth': 'Crecimiento personal',
          'Check compatibility': 'Compruebe la compatibilidad',
          'Increase relationship satisfaction': 'Estar más satisfecho con mi relación',
          'Fix relationship problems': 'Solucionar problemas de relación',
          'Build a strong marriage': 'Construir un matrimonio sólido',
          'All above': 'Encontrar el amor y comprenderme mejor',
          'All above in relationships': 'Mejorar la relación',
        },
        specialOffer: {
          header: 'Oferta especial',
          trialTitle: 'Lectura personalizada para ',
          total: 'Total hoy:',
          discount: 'Descuento expirado',
          saveMoney: 'Ahorre {{discount}} cada periodo',
          perWeeksCostOld: 'Coste post-prueba de 30 días',
          perWeeksCost: 'Incluye 1 semana de prueba de lectura personal',
          optionTitle: 'Elige la opción:',
          expensiveOption:
            'Apoyar a quienes necesitan elegir el precio de prueba más bajo eligiendo esta opción!',
          getAccess: 'obtener mi lectura',
        },
        guaranteeText: 'Garantías de pago',
        blurredReport: {
          title: 'Su informe',
          zodiac: 'Signo del zodiaco',
          gender: 'Género',
          contentTitle: 'Contenido',
          points: [
            'Compatibilidad con su pareja en otras áreas de su vida.',
            'Análisis profundo de las relaciones con su pareja basado en ' +
              'un sistema único de correspondencia de cartas natales.',
            'Guía sencilla y práctica para mejorar la relación con su pareja',
            'Advertencia sobre acontecimientos astrológicos y consejos prácticos ' +
              'que te ayudarán a superar bien este periodo',
            'Tu horóscopo y los próximos acontecimientos para 2023',
            'Tus puntos fuertes y débiles exclusivos y cómo sacarles el máximo partido',
          ],
          personalityTitle: 'Personalidad',
          personalityContent:
            'Acuario es el undécimo signo del zodíaco y representa la inventiva y la brillantez. ' +
            'Sus planetas regentes son Urano y Saturno, lo que confiere a este signo una mezcla única de ' +
            'personalidad restringida pero liberada. Saturno se asocia con los límites y las fronteras, ' +
            'mientras que Urano es',
          accessDescription: 'Para leer el informe completo necesita obtener aceess',
          getAccess: 'obtener mi lectura',
        },
        userReviews: {
          title: 'Los usuarios nos adoran',
          list: {
            user1: {
              review:
                'Fue realmente útil y me proporcionó la claridad que necesitaba para mi ' +
                'situación sentimental actual. Me da esperanzas de que mi relación aún pueda ' +
                'salvarse. Muchas gracias. Muy recomendable.',
            },
            user2: {
              review:
                'Increíble, ¡absolutamente increíble! Las afirmaciones que recibí y los consejos nutritivos ' +
                'valieron la pena. De verdad, ¡gracias!',
            },
            user3: {
              review:
                'Me ayuda a confiar en mí misma y en mis decisiones para el futuro dándome ' +
                'seguridad con la información que recibo. Mis metas y sueños se van a cumplir y ' +
                'ahora confío en mí misma para hacer lo que necesito y deseo.',
            },
          },
        },
        benefits: {
          title: 'Lo que obtienes',
          points: [
            'Su plan personalizado',
            'Asesoramiento 1:1 de su propio astrólogo',
            'Encontrar la pareja más compatible',
            'IComprensión de sus patrones de relación y de sus necesidades emocionales y sexuales.',
            'Mejor comprensión de uno mismo',
          ],
        },
        featuredInTitle: 'Como aparece en',
        QASection: {
          title: 'La gente suele preguntar',
          question1: '¿Cuál es la precisión de la lectura astrológica en esta plataforma?',
          answer1:
            'La exactitud en astrología, siendo subjetiva, puede brindar insights valiosos. ' +
            'Nuestra plataforma, con algoritmos avanzados y expertos, busca maximizar esta precisión.',
          question2: '¿Puedo obtener una lectura de compatibilidad de relaciones?',
          answer2:
            'Sí, se puede obtener una lectura de compatibilidad para las relaciones. Este tipo de lectura ' +
            'incluye un análisis astrológico exhaustivo de dos personas para evaluar su compatibilidad en ' +
            'diversas áreas, como el amor, la comunicación y los valores compartidos.',
          question3: '¿Son confidenciales y privadas las lecturas astrológicas de esta plataforma?',
          answer3:
            'Sí, todas las lecturas de nuestra plataforma son estrictamente confidenciales. Respetamos la ' +
            'privacidad de nuestros usuarios y garantizamos que todos los datos personales y lecturas se ' +
            'almacenan de forma segura y no se comparten con terceros sin consentimiento.',
        },
        charge: {
          trial:
            'Sólo se le cobrará <bold>{{trialPrice}}  por sus 7 días de prueba.</bold><br/>' +
            'Te enviaremos un <bold>recordatorio por correo electrónico</bold> antes de que finalice el ' +
            'periodo de prueba. <bold>Cancele en cualquier momento.</bold>',
          europeanTrial:
            'Sólo se le cobrará <bold>{{europeanTrialPrice}}  por sus 7 días de prueba.</bold><br/>' +
            'Te enviaremos un <bold>recordatorio por correo electrónico</bold> antes de que finalice el ' +
            'periodo de prueba. <bold>Cancele en cualquier momento.</bold>',
          trialUSStart: 'Está suscribiéndose durante 2 semanas al servicio ',
          trialUSEnd:
            '. Al continuar acepta que si no cancela antes del final de la prueba de 7 días por el {{trialPrice}} se' +
            ' le cobrará automáticamente {{fullPrice}} cada 2 semanas hasta que cancele en la configuración. El' +
            ' cargo aparecerá en tu factura como {{domainUrl}}. Más información sobre la política de cancelación y' +
            ' reembolso en',
          trialUS:
            'Usted se inscribe en la suscripción mensual al servicio NEBULA. Al continuar acepta que si no cancela antes del final de la prueba de 7 días para el {{trialPrice}} se le cobrará automáticamente {{fullPrice}} cada 30 días hasta que cancele en la configuración. El cargo aparecerá en tu factura como {{domainUrl}}. Más información sobre la política de cancelación y reembolso en ',
        },
        chargeTest:
          'Sólo se le cobrará <bold>{{price}} por sus 7 días de prueba.</bold><br/>' +
          'Te enviaremos un <bold>recordatorio por correo electrónico</bold> antes de que finalice el ' +
          'periodo de prueba. <bold>Cancele en cualquier momento.</bold>',
        description: {
          text:
            'Está contratando una suscripción de 2 semanas al NEBULA servicio. ' +
            'Al continuar aceptas que si no cancelas antes del final de los 7 días de prueba por ' +
            'el importe de <bold>{{trialPrice}}</bold> ' +
            'se te cobrarán automáticamente <bold>{{plan}}</bold> ' +
            'cada 2 semanas hasta que canceles en ajustes. Más información sobre ' +
            'la política de cancelación y reembolso en ',
          textEU:
            'Está contratando una suscripción de 2 semanas al NEBULA servicio. ' +
            'Al continuar aceptas que si no cancelas antes del final de los 7 días de prueba por ' +
            'el importe de <bold>{{europeanTrialPrice}}</bold> ' +
            'se te cobrarán automáticamente <bold>{{plan}}</bold> ' +
            'cada 2 semanas hasta que canceles en ajustes. Más información sobre ' +
            'la política de cancelación y reembolso en ',
          terms: 'Política de suscripción',
          termsEU: 'Política de suscripción',
          termsExperiment: 'Condiciones de suscripción',
        },
        trialChoiceDescription: {
          header:
            'Hemos ayudado a millones de personas a tener vidas más felices y mejores relaciones, y ' +
            'queremos ayudarte a ti también.',
          money:
            'El dinero no debería interponerse en el camino de encontrar una guía astrológica que ' +
            'finalmente funcione. Así que, elige una cantidad que creas razonable para probarnos por una semana.',
          paymentDescription:
            'Nos cuesta {{priceForUs}} para ofrecer una prueba de 7 días, pero elige la cantidad' +
            ' con la que te sientas cómodo/a.',
        },
      },
      trialChoice: {
        header:
          'Hemos ayudado a millones de personas a tener vidas más felices y mejores relaciones, y ' +
          'queremos ayudarte a ti también.',
        money:
          'El dinero no debería interponerse en el camino de encontrar una guía astrológica que finalmente funcione. ' +
          'Así que, elige una cantidad que creas razonable para probarnos por una semana.',
        paymentDescription:
          'Nos cuesta {{priceForUs}} para ofrecer una prueba de 7 días, pero elige la cantidad' +
          ' con la que te sientas cómodo/a.',
        expensiveOption:
          '¡Esta opción nos ayudará a apoyar a aquellos que necesitan elegir los precios de prueba más bajos!',
        button: 'Ver mi plan',
        note: '*Costo de prueba desde Febrero 2023',
        information: {
          title: 'personas se unieron hoy',
          list: [
            'talen**** <b>eligió {{ middlePrice}}</b>',
            'janin**** <b>eligió {{ middlePrice}}</b>',
            'peter**** <b>eligió {{ middlePrice}}</b>',
            'edwar**** <b>eligió {{ lowerPrice }}</b>',
            'chiar**** <b>eligió {{ middlePrice}}</b>',
            'anton**** <b>eligió {{ lowerPrice }}</b>',
            'heath**** <b>eligió {{ middlePrice}}</b>',
            'lucas**** <b>eligió {{ lowerPrice }}</b>',
            'tamar**** <b>eligió {{ lowerPrice }}</b>',
            'izzy_**** <b>eligió {{ greaterPrice }}</b>',
            'coryz**** <b>eligió {{ highPrice}}</b>',
            'diann**** <b>eligió {{ middlePrice}}</b>',
            'gofor**** <b>eligió {{ lowerPrice }}</b>',
            'benne**** <b>eligió {{ middlePrice}}</b>',
            'matth**** <b>eligió {{ middlePrice}}</b>',
            'Olive**** <b>eligió {{ highPrice}}</b>',
            'teres**** <b>eligió {{ middlePrice}}</b>',
            'tommy**** <b>eligió {{ lowerPrice }}</b>',
            'betha**** <b>eligió {{ greaterPrice }}</b>',
            'yukin**** <b>eligió {{ middlePrice}}</b>',
            'ilove**** <b>eligió {{ greaterPrice }}</b>',
            'fitzg**** <b>eligió {{ lowerPrice }}</b>',
            'r_mel**** <b>eligió {{ middlePrice}}</b>',
            'clare**** <b>eligió {{ greaterPrice }}</b>',
            'elize**** <b>eligió {{ lowerPrice }}</b>',
            'carlo**** <b>eligió {{ greaterPrice }}</b>',
            'lucil**** <b>eligió {{ lowerPrice }}</b>',
            'ellen**** <b>eligió {{ highPrice}}</b>',
            'carli**** <b>eligió {{ middlePrice}}</b>',
            'rebec**** <b>eligió {{ greaterPrice }}</b>',
            'cinth**** <b>eligió {{ greaterPrice }}</b>',
            'flavi**** <b>eligió {{ lowerPrice }}</b>',
            'awint**** <b>eligió {{ middlePrice}}</b>',
            'linda**** <b>eligió {{ lowerPrice }}</b>',
            'jazzy**** <b>eligió {{ lowerPrice }}</b>',
            'claud**** <b>eligió {{ lowerPrice }}</b>',
            'katja**** <b>eligió {{ highPrice}}</b>',
            'james**** <b>eligió {{ middlePrice}}</b>',
            'krist**** <b>eligió {{ lowerPrice }}</b>',
            'alice**** <b>eligió {{ lowerPrice }}</b>',
            'johnr**** <b>eligió {{ lowerPrice }}</b>',
            'lucyl**** <b>eligió {{ lowerPrice }}</b>',
            'hanna**** <b>eligió {{ greaterPrice }}</b>',
            'dariu**** <b>eligió {{ highPrice}}</b>',
            'jean.**** <b>eligió {{ middlePrice}}</b>',
            'palom**** <b>eligió {{ highPrice}}</b>',
            'oweng**** <b>eligió {{ lowerPrice }}</b>',
            'aless**** <b>eligió {{ lowerPrice }}</b>',
            'nadia**** <b>eligió {{ lowerPrice }}</b>',
            'agnes**** <b>eligió {{ lowerPrice }}</b>',
          ],
        },
      },
      productOverview: {
        banner:
          'Obtén 35% de rebaja + 7 días de prueba con el código <highlight>Nebula22</highlight>',
        title: 'Oye, <zodiac>{{zodiac}}</zodiac> Sol,',
        subtitle:
          'Según tus respuestas y tu carta natal, ' +
          '<highlight>hemos preparado tu plan personalizado.</highlight>',
        includedTitle: 'Aspectos destacados de tu plan:',
        list: {
          advice: 'Consejos 1:1 de tu propio astrólogo',
          finding: 'Encuentra la pareja más compatible',
          improving: 'Mejora la relación con tu pareja',
          patterns: 'Información de tus patrones de relación y necesidades emocionales y sexuales',
          betterUnderstanding: 'Mejora comprensión de uno mismo',
          loved: 'y tu amado/a',
          reviewsTitle: 'Reseñas de los clientes:',
          featuredTitle: 'Como aparece en:',
          button: 'Continuar',
        },
        reviewsTitle: 'Reseñas de los clientes:',
        featuredTitle: 'Сomo aparece en:',
        button: 'Continuar',
      },
      trialPayment: {
        specialOffer: 'Oferta Especial',
        title: 'Comienza tu prueba de 7 días',
        subtitle: {
          text: 'Cancela cuando quieras.',
          noPressure: 'Sin presión.',
          other: 'Sin compromiso. ',
        },
        total: 'Total de hoy:',
        readingTitle: 'Obtén tu lectura',
        readingSubtitle: 'Personalizada solo para ti.',
        specialCode: '¡Código Nebula 22 aplicado!',
        timer: 'Reservado para',
        price: {
          text: 'Su costo por 2 semanas ',
          trial: 'después de la prueba',
          save: 'Ahorra ',
          readingTitle: 'Tu precio para la lectura:',
          period: ' cada período',
        },
        information: {
          title: 'personas aprendieron insights de relaciones en la última hora',
          list: [
            'wesle**** <b>obtuvo 7 insights</b>',
            'karol**** <b>obtuvo 2 insights</b>',
            'david**** <b>obtuvo 9 insights</b>',
            'craig**** <b>obtuvo 1 insights</b>',
            'brian**** <b>obtuvo 8 insights</b>',
            'joann**** <b>obtuvo 5 insights</b>',
            'grego**** <b>obtuvo 6 insights</b>',
            'amyma**** <b>obtuvo 6 insights</b>',
            'sandy**** <b>obtuvo 1 insights</b>',
            'chloe**** <b>obtuvo 9 insights</b>',
            'rache**** <b>obtuvo 9 insights</b>',
            'darre**** <b>obtuvo 4 insights</b>',
            'karin**** <b>obtuvo 1 insights</b>',
            'miche**** <b>obtuvo 12 insights</b>',
            'georg**** <b>obtuvo 8 insights</b>',
            'hanna**** <b>obtuvo 4 insights</b>',
            'erica**** <b>obtuvo 3 insights</b>',
            'kelly**** <b>obtuvo 8 insights</b>',
            'jessi**** <b>obtuvo 6 insights</b>',
            'geral**** <b>obtuvo 8 insights</b>',
            'patri**** <b>obtuvo 1 insights</b>',
            'carlo**** <b>obtuvo 2 insights</b>',
            'melan**** <b>obtuvo 5 insights</b>',
            'gordo**** <b>obtuvo 6 insights</b>',
            'marye**** <b>obtuvo 12 insights</b>',
            'calvi**** <b>obtuvo 7 insights</b>',
            'rebec**** <b>obtuvo 7 insights</b>',
            'yolan**** <b>obtuvo 10 insights</b>',
            'simon**** <b>obtuvo 8 insights</b>',
            'maggi**** <b>obtuvo 10 insights</b>',
            'jade.**** <b>obtuvo 1 insights</b>',
            'court**** <b>obtuvo 7 insights</b>',
            'jesse**** <b>obtuvo 6 insights</b>',
            'tammy**** <b>obtuvo 2 insights</b>',
            'felic**** <b>obtuvo 11 insights</b>',
            'ivan.**** <b>obtuvo 10 insights</b>',
            'carol**** <b>obtuvo 2 insights</b>',
            'karen**** <b>obtuvo 9 insights</b>',
            'trish**** <b>obtuvo 6 insights</b>',
            'pamel**** <b>obtuvo 9 insights</b>',
            'marks**** <b>obtuvo 2 insights</b>',
            'brend**** <b>obtuvo 9 insights</b>',
            'lucyl**** <b>obtuvo 6 insights</b>',
            'hanna**** <b>obtuvo 9 insights</b>',
            'dariu**** <b>obtuvo 11 insights</b>',
            'jean.**** <b>obtuvo 12 insights</b>',
            'palom**** <b>obtuvo 9 insights</b>',
            'oweng**** <b>obtuvo 4 insights</b>',
            'aless**** <b>obtuvo 8 insights</b>',
            'nadia**** <b>obtuvo 5 insights</b>',
          ],
        },
        charge: {
          trial:
            'Solo se te cobrará <bold>{{trialPrice}}</bold> por tu prueba de 7 días.<br/>' +
            'Te <bold>enviaremos un recordatorio por email</bold>  antes de que termine tu período de prueba. ' +
            '<bold>Cancela en cualquier momento.</bold>',
          europeanTrial:
            'Solo se te cobrará <bold>{{europeanTrialPrice}}</bold> por tu prueba de 7 días.<br/>' +
            'Te <bold>enviaremos un recordatorio por email</bold>  antes de que termine tu período de prueba. ' +
            '<bold>Cancela en cualquier momento.</bold>',
          email:
            'Te <bold>enviaremos un recordatorio por email</bold>  antes de que termine tu período de prueba. ' +
            '<bold>Cancela en cualquier momento.</bold>',
          reading: 'Solo se te cobrará <bold>{{europeanTrialPrice}}</bold> para tu lectura.',
        },
        pay: 'Obtenga acceso',
        description: {
          textUS:
            'Al continuar, aceptas que si no cancelas antes del final de la prueba de 7 días, ' +
            'se te cobrará automáticamente <bold>{{plan}}</bold> cada 2 semanas ' +
            'hasta que canceles en las configuraciónes. Aprende más sobre la política de cancelación y reembolso en ',
          text:
            'Extendido quincenalmente después de 1 semana de prueba al precio de {{plan}}. ' +
            'Impuestos calculados al finalizar la compra.',
          terms: 'Términos de suscripción',
        },
        address: `${config.companyName}, Athalassas, 62, ENTRESUELO, Strovolos, 2012, Nicosia, Chipre`,
      },
      payment: {
        title: 'Elige el método de pago',
        divider: 'o',
        form: {
          default: {
            submitButtonText: 'Inicia la Prueba de 7 Días',
            charged: 'Solo se te cobrará ',
            trial: ' por tu prueba de 7 días.',
          },
          resign: {
            submitButtonText: 'Comprar',
          },
        },
        charge: {
          trial:
            'Solo se te cobrará <bold>{{price}} por tu prueba de 7 días.</bold><br/>' +
            'Te <bold>enviaremos un recordatorio por email</bold> antes de que termine tu período de prueba. ' +
            'Cancela en cualquier momento.',
          trialUS:
            'Solo se te cobrará <bold>{{price}} por tu prueba de 7 días.</bold><br/>' +
            'Te <bold>enviaremos un recordatorio por email</bold> antes de que termine tu período de prueba. ' +
            'Cancela en cualquier momento. El cargo aparecerá en tu factura como {{domainUrl}}',
          email:
            'Te <bold>enviaremos un recordatorio por email</bold> antes de que termine tu período de prueba. ' +
            'Cancela en cualquier momento.',
        },
        pay: {
          trial: 'Por tu <bold>prueba de 7 días</bold>',
          price: 'Solamente',
          tax: 'Impuesto al valor agregado',
          total: 'Total',
        },
        resources: 'Pagos garantizados y seguros',
      },
      additionalDiscount: {
        title: '¡Ahorra un 65%!',
        plan: '65% de rebaja en tu plan personalizado',
        trial: 'prueba de 7 días',
        price: 'en vez de',
        button: '¡Obtén un descuento secreto!',
      },
      trialPaymentWithDiscount: {
        offer: 'Descuento SECRETO <bold>de 65%</bold> aplicado',
        title: '¡Obtienes un descuento secreto!',
        pay: 'Comienza tu prueba de 7 días',
        subtitle: 'Sin presión. Cancela cuando quieras.',
        specialCode: '¡Descuento secreto aplicado!',
        price: 'Tu costo por 2 semanas después de la prueba:',
        priceExperiment: 'Tu costo por 30 días después de la prueba:',
        saveMoney: 'Te ahorras {{savedMoney}} cada periodo',
        saveMoneyExperiment: 'Te ahorras {{savedMoney}}',
        total: 'Total hoy:',
        description: `Al continuar, aceptas que si no cancelas antes de que finalicen los 7 días de prueba, se te cobrará automáticamente {{ discountPrice }} durante el periodo introductorio 2 semanas días y, a partir de entonces, la tarifa estándar de {{ fullPrice }} cada 2 semanas hasta que canceles en ajustes. Más información sobre la política de cancelación y reembolso en`,
        descriptionExperiment: `Al continuar, aceptas que si no cancelas antes de que finalicen los 7 días de prueba, se te cobrará automáticamente {{ discountPrice }} durante el periodo introductorio de 30 días y, a partir de entonces, la tarifa estándar de {{ fullPrice }} cada 30 días hasta que canceles en ajustes. Más información sobre la política de cancelación y reembolso en`,
        terms: 'Términos de suscripción',
      },
      unlimitedReadings: {
        title: 'Accede a las guías astrológicas y lecturas de compatibilidad ilimitadas',
        contains: {
          affirmations: 'afirmaciones personales para cada día',
          moreInsights: 'más información en menos tiempo',
          learnVisually: 'aprender visualmente',
          minutesToRead: 'minutos para leer',
        },
        benefits: {
          unlimitedCompatibilityChecks: 'comprueba la compatibilidad tantas veces como necesites',
          manyInsight:
            'cientos de ideas que le ayudarán a alcanzar sus objetivos en las relaciones de pareja',
          dailyHoroscopes: 'horóscopos diarios personalizados y guías astrológicas sin anuncios',
          autoRenewable: 'plan autorenovable de 1 mes, cancelación en cualquier momento',
        },
        button: 'Añadir lecturas ilimitadas',
        skip: 'Omita esta oferta y siga adelante',
        disclaimer:
          'Nota: Además de su suscripción, se cargará en su cuenta {{price}} por los complementos seleccionados al hacer clic en Añadir lecturas ilimitadas. Los artículos de esta página son suscripciones de {{period}}. A menos que la canceles en tu perfil antes de que finalice el periodo en curso, aceptas que la suscripción se renueve automáticamente al final de cada periodo. Si necesita ayuda sobre cómo cancelarla, visite nuestra página ',
      },
      upsells: {
        header: {
          step1: 'Comprar informe',
          step2: 'Consultas',
          step3: 'Acceso al producto',
          banner: {
            title: '¡Gracias!',
            text: '¡Tu pedido fue exitoso!',
          },
          title: 'Elige tu oferta de registro',
          subtitle: 'Disponible solo ahora',
        },
        headerNew: {
          addReport: 'Añadir informe',
          unlimitedReadings: 'Lecturas ilimitadas',
          addConsultation: 'Añadir consulta',
          accessProduct: 'Acceso al producto',
        },
        footer: {
          description:
            '*Se le cobrará por los servicios o productos adicionales seleccionados en el momento de la compra.' +
            'Este no es un pago recurrente.',
        },
        button: 'Obtener mi copia',
        link: 'Omitir esta oferta y continuar',
      },
      trialCredits: {
        header: {
          step1: 'Comprar informe',
          step2: 'Consultas',
          step3: 'Acceso al producto',
          title: 'Más para ti',
          subtitle: 'Oferta exclusiva recomendada para que alcances tus objetivos más rápido',
        },
        payment: {
          title: 'Tu consulta individual única',
          consultation:
            '<highlight>De 30 minutos privada </highlight><br />' +
            '<highlight>consulta con un experto</highlight>',
          description:
            'Puede solicitar una predicción del futuro, un análisis de compatibilidad en vivo; compatibilidad' +
            ' amorosa, etc.',
          offerPrice: 'Oferta de precio única:',
          originalPrice: {
            price: 'Precio original:',
            discount: 'Ahorrar',
          },
        },
        footer: {
          description:
            '*Se le cobrará por los servicios o productos adicionales seleccionados en el momento de la compra.' +
            '<br /> Esto es un pago no recurrente.',
        },
        button: 'Obtener mi consulta',
        link: 'Omitir esta oferta y continuar',
      },
      onboardingLP: {
        welcome: 'Bienvenido',
        title: 'AskNebula',
        text: 'Consultas y lecturas <highlight>hiper personalizadas con los mejores especialistas</highlight>',
      },
      talkWithExpert: {
        text:
          'Como parte de su plan de orientación, debe hablar con el especialista psíquico sobre cómo ' +
          '<highlight>{{userAnswer}}</highlight>',
        single: {
          perfectPartner: 'encontrar tu pareja perfecta',
          understandBetter: 'comprenderte mejor a ti mismo/a',
          loadingBoth: 'encontrar tu pareja perfecta y comprenderte mejor a ti mismo/a',
        },
        relationship: {
          fixProblems: 'solucionar problemas de pareja',
          relationshipSatisfaction: 'mejorar la satisfacción en su relación de pareja',
          both: 'solucionar problemas de pareja y mejorar la satisfacción en su relación de pareja',
        },
      },
      questions: {
        title: '¿Qué es lo que preguntan nuestros usuarios?',
        tip: '* basado en una encuesta y con el consentimiento de los participantes',
        firstMessage: {
          single: '¿Mi ex todavía me ama?',
          notSingle: '¿Cómo saber si mi pareja me engaña?',
        },
        secondMessageSingle: {
          understandBetter: '¿Cuál es el propósito divino en mi vida?',
          default: ' ¿Cuándo me casaré?',
        },
        secondMessageRelationship: {
          relationshipSatisfaction: '¿Es mi alma gemela?',
          default: ' ¿Por qué mi pareja está tan distante?',
        },
        thirdMessageSingle: {
          understandBetter: '¿Qué debo hacer para entenderme mejor a mi mismo/a?',
          default: '¿Qué debo hacer para encontrar a mi pareja perfecta?',
        },
        thirdMessageRelationship: {
          relationshipSatisfaction:
            '¿Qué debo hacer para mejorar la satisfacción de la relación de pareja?',
          default: '¿Qué debo hacer para solucionar mis problemas de relación?',
        },
      },
      credits: {
        text:
          'Su plan incluye 150 créditos. <highlight> Recibirá {{creditsAmount}} créditos adicionales cada ' +
          '30 días para discutir el progreso con su especialista </highlight>',
      },
      loadingLP: {
        title:
          '¡Hurra! <highlight>Estamos</highlight> seleccionando <highlight>al especialista apropiado</highlight>',
        list: [
          'Estamos analizando sus respuestas...',
          'Estamos obteniendo acceso a una lista de especialistas...',
          'Estamos seleccionando a los mejores especialistas para ti...',
          'Personalizamos su experiencia...',
        ],
      },
      additionalQuestionOne: {
        title: '¿Te gustaría obtener aún más?',
        text: '¡Habla sobre tus relaciones y otros problemas de la vida con un asesor psíquico!',
        redirectButton: 'Encontrar asesor',
        continueButton: 'No me interesa',
      },
      additionalQuestionTwo: {
        title:
          '¿Estás seguro de que no quieres encontrar respuestas que afecten la calidad de tu vida?',
        text:
          'AskNebula cuenta con los mejores asesores psíquicos que pueden proporcionar lecturas precisas ' +
          '<bold>sobre el amor y las relaciones, la vida y otros temas.</bold>',
        redirectButton: 'No quiero respuestas',
        continueButton: 'Quiero encontrar respuestas',
      },
    },
    'intro-offer': {
      credits: {
        text:
          'Su plan incluye {{creditsAmount}} créditos. <highlight> Recibirá 300 créditos adicionales cada ' +
          '2 semanas para discutir el progreso con su especialista </highlight>',
      },
    },
    'natal-chart': {
      progressBar: 'Tome las riendas de su vida',
      billingScreen: {
        description:
          'Al continuar, aceptas que se te cobre automáticamente {{price}} cada mes hasta que lo canceles en la configuración. Más información sobre la política de cancelación y reembolso en ',
        trialDescription:
          'Usted se está inscribiendo en <bold>7 días de suscripción</bold> al servicio NEBULA. Al continuar acepta que si no cancela antes del final de los <bold>7 días de prueba por {{trialPrice}}</bold>, se le cobrará automáticamente {{price}} cada mes hasta que cancele en la configuración. Más información sobre la política de cancelación y reembolso en',
        terms: 'Condiciones de suscripción.',
      },
    },
    reviewsList: {
      user1: {
        name: '@ria._.panwar',
        date: '09/17/2021',
        text:
          'Nebula Horoscope cuenta hechos realistas sobre la vida cotidiana, con los que puedo identificarme ' +
          'fácilmente. Muestra una dirección.',
      },
      user2: {
        name: '@jp63_',
        date: '09/29/2021',
        text: 'Me hace sentir seguro, atento, cálido e inteligente.',
      },
      user3: {
        name: '@therealslimmazi',
        date: '09/28/2021',
        text: 'Me encanta tener la opción "mi perfil". Me encanta aprender sobre mí.',
      },
    },
    ultimatePackages: {
      week1: {
        title: 'Semana 1:',
        text: 'Análisis numerológico',
      },
      week2: {
        title: 'Semana 2:',
        text: 'Lectura de Tarot',
      },
      week3: {
        title: 'Semana 3:',
        text: 'Guía de quiromancia',
      },
      'week4-6': {
        title: 'Semanas 4-6:',
        text: 'Lecturas de ángeles',
      },
      'week7-10': {
        title: 'Semanas 7-10:',
        text: 'Matriz de destino y predicción de riqueza',
      },
    },
    regularPackages: {
      week1: {
        title: 'Semana 1:',
        text: 'Análisis numerológico',
      },
      week2: {
        title: 'Semana 2:',
        text: 'Lectura de Tarot',
      },
      week3: {
        title: ' Semana 3:',
        text: 'Guía de quiromancia',
      },
    },
    plans: {
      long: '10 lecturas',
      short: '3 lecturas',
      numerologyAnalysis: {
        oldPriceDescription: 'era',
        discount: '50% DE DESCUENTO',
        title: 'ANÁLISIS NUMEROLOGÍA',
      },
      tarotReading: {
        oldPriceDescription: 'era',
        discount: '45% DE DESCUENTO',
        title: 'LECTURA DE TAROT',
      },
      palmistryGuide: {
        oldPriceDescription: 'era',
        discount: '30% DE DESCUENTO',
        title: 'GUÍA DE QUIROMANCIA',
      },
      ultraPack: {
        oldPriceDescription: 'precio regular',
        discount: '50% DE DESCUENTO',
        description: '(3 en 1 + 2 lecturas secretas de bonificación)',
        title: 'ULTRA PACK',
      },
    },
    progressHeader: {
      step1: {
        top: 'Comprar',
        bottom: 'informe',
      },
      step2: {
        top: 'Agregar',
        bottom: 'lecturas',
      },
      step3: {
        top: 'Acceder',
        bottom: 'producto',
      },
    },
    progressBarTitle: {
      profile: 'Tu perfil',
      personality: 'Rasgos de personalidad',
      relationship: 'Patrón de Relación',
      partner: 'El perfil de tu pareja',
      personalityPatterns: 'Patrones de Relación y Personalidad',
    },
    agreement: {
      text: 'Al continuar estoy de acuerdo con ',
      policy: 'Política de privacidad ',
      separator: 'y ',
      terms: 'Términos de uso',
    },
    paymentFailed: {
      title: 'Pago fallido',
      text: 'Tu transacción ha fallado por algún error técnico. Inténtalo de nuevo.',
      button: 'Intentar de nuevo',
    },
    payment: {
      number: 'Número de tarjeta',
      date: 'Fecha de caducidad',
    },
    zodiacDescription: {
      aquarius: {
        male: 'Revolucionario en pensamiento, el hombre Acuario rompe barreras, imaginando un mañana más brillante e inconvencional.',
        female:
          'La mujer Acuario, con su espíritu vanguardista, baila a su propio ritmo, siempre abogando por la innovación.',
      },
      aries: {
        male: 'El hombre Aries avanza con una energía inigualable, siempre listo para conquistar nuevas fronteras.',
        female:
          'Radiante de confianza, la mujer Aries a menudo lidera el grupo, impulsada por la ambición y la determinación.',
      },
      cancer: {
        male: 'Profundamente intuitivo, el hombre Cáncer protege su ámbito emocional, obteniendo fuerza de los lazos familiares.',
        female:
          'El espíritu cuidador de una mujer Cáncer crea un abrazo envolvente de comodidad para sus seres queridos.',
      },
      capricorn: {
        male: 'El hombre Capricornio, con la tenacidad de una cabra montesa, escala los picos de la vida con dedicación disciplinada.',
        female:
          'Aterrizada y sabia, la mujer Capricornio se presenta como un pilar de resistencia, fusionando ambición con propósito.',
      },
      gemini: {
        male: 'Siempre curioso, el hombre Géminis es un torbellino de ideas, cambiando a menudo de temas con entusiasmo.',
        female:
          'Deslumbrante con ingenio, la mujer Géminis encanta con su versatilidad, adaptándose constantemente al cambio.',
      },
      leo: {
        male: 'Con su porte regio, el hombre Leo tiene un carisma magnético que demanda el foco de atención.',
        female:
          'Vibrante y segura, la mujer Leo irradia calidez, gobernando su dominio con generosidad y gracia.',
      },
      libra: {
        male: 'Impulsado por la armonía, el hombre Libra equilibra con gracia los desafíos de la vida, siempre buscando el punto medio.',
        female:
          'Encanto personificado, la mujer Libra es la encarnación de la elegancia, manejando la diplomacia con un toque artístico.',
      },
      pisces: {
        male: 'Soñador y empático, el hombre Piscis navega en los reinos de la emoción, expresando a menudo su alma a través del arte.',
        female:
          'Eterea y compasiva, la mujer Piscis siente profundamente, tejiendo cuentos de romance y magia a su paso.',
      },
      sagittarius: {
        male: 'Con ansias de viajar en su corazón, el hombre Sagitario persigue el conocimiento y la aventura, siempre siendo el eterno optimista.',
        female:
          'Vivaz y de espíritu libre, la mujer Sagitario recorre la vida, difundiendo alegría y entusiasmo contagioso.',
      },
      scorpio: {
        male: 'El hombre Escorpio se sumerge profundamente, con una intensidad que puede desentrañar los misterios de la vida, impulsado por la pasión y la determinación.',
        female:
          'La enigmática mujer Escorpio posee un encanto magnético, sus profundidades velan fuerza y vulnerabilidad.',
      },
      taurus: {
        male: 'El hombre Tauro valora la estabilidad, mostrando a menudo una potente mezcla de resistencia y sensualidad.',
        female:
          'Aterrizada y elegante, la mujer Tauro aprecia la belleza y el lujo en la vida, buscando siempre el confort.',
      },
      virgo: {
        male: 'Con un ojo en el detalle, el hombre Virgo busca la perfección, siendo a menudo el solucionador metódico en la habitación.',
        female:
          'Perspicaz y diligente, la mujer Virgo navega la vida con destreza analítica y un corazón puro.',
      },
    },
    zodiac: {
      aquarius: 'acuario',
      aries: 'aries',
      cancer: 'cáncer',
      capricorn: 'capricornio',
      gemini: 'géminis',
      leo: 'leo',
      libra: 'libra',
      pisces: 'piscis',
      sagittarius: 'sagitario',
      scorpio: 'escorpio',
      taurus: 'tauro',
      virgo: 'virgo',
    },
    randomZodiacDescription: {
      templates: [
        `Una unión de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} combina dos energías astrológicas distintas.`,
        `La dinámica entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} siempre es de curiosidad cósmica.`,
        `En el tapiz zodiacal, los hilos de un {{translate gender}} {{translate zodiac}} se entrelazan de manera única con los de un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Cuando un {{translate gender}} {{translate zodiac}} conoce a un {{translate partnerGender}} {{translate partnerZodiac}}, el universo seguramente se detiene por un momento.`,
        `Un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} juntos es como dos estrellas alineándose en la danza celestial.`,
        `La historia de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} está escrita en las constelaciones.`,
        `Cada encuentro entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} guarda un universo de posibilidades.`,
        `El vínculo celestial entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} es un enigma esperando ser explorado.`,
        `Los cielos susurran historias de interacciones entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Un {{translate gender}} {{translate zodiac}} uniendo fuerzas con un {{translate partnerGender}} {{translate partnerZodiac}} es un evento cósmico en sí mismo.`,
        `En la gran danza cósmica, un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} encuentran un ritmo único para ellos.`,
        `Los misterios del universo se reflejan en el encuentro de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Las energías astrales de un {{translate gender}} {{translate zodiac}} se fusionan de forma única con las de un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}, yace un viaje interestelar de descubrimiento.`,
        `Las estrellas brillan de manera diferente cuando un {{translate gender}} {{translate zodiac}} alinea sus caminos con un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Maravillas cósmicas se despliegan cuando un {{translate gender}} {{translate zodiac}} se encuentra con un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} comparten un vínculo que resuena con los misterios del universo.`,
        `El relato cósmico toma un giro interesante con la convergencia de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `En la inmensidad del cosmos, la alianza de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} brilla intensamente.`,
        `La saga celestial de un {{translate gender}} {{translate zodiac}} encontrándose con un {{translate partnerGender}} {{translate partnerZodiac}} es para las eras astrales.`,
        `Cada interacción entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} teje un nuevo cuento estelar.`,
        `El universo hace una pausa para presenciar la sinergia entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} juntos forman una constelación de posibilidades.`,
        `En el gran teatro del cosmos, un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} interpretan un dúo notable.`,
        `Un {{translate gender}} {{translate zodiac}} compartiendo espacio con un {{translate partnerGender}} {{translate partnerZodiac}} crea una resonancia celestial única.`,
        `Las galaxias asienten en acuerdo cuando un {{translate gender}} {{translate zodiac}} cruza caminos con un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Hay una canción cósmica que solo un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} pueden armonizar juntos.`,
        `Los vientos astrales cambian de dirección cuando un {{translate gender}} {{translate zodiac}} conoce a un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `En la gran narrativa del zodiaco, el cuento de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} tiene su propio encanto.`,
        `La interacción entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} es una danza cósmica de pasos únicos.`,
        `Un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} juntos forman un espectáculo estelar digno de observación.`,
        `Cuando un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} se unen, el cosmos se inclina con interés.`,
        `El viaje de un {{translate gender}} {{translate zodiac}} junto a un {{translate partnerGender}} {{translate partnerZodiac}} es una historia celestial en creación.`,
        `El universo tararea una melodía especial para la alianza de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} juntos ocupan un lugar especial en las crónicas del zodiaco.`,
        `Las corrientes cósmicas fluyen de manera distintiva cuando un {{translate gender}} {{translate zodiac}} interactúa con un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Los reinos astrales toman nota cuando un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} forman una conexión.`,
        `En la vasta danza cósmica, un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} encuentran un ritmo propio.`,
        `Un {{translate gender}} {{translate zodiac}} encontrándose con un {{translate partnerGender}} {{translate partnerZodiac}} es un momento que siempre esperan las estrellas.`,
        `Las esferas celestiales susurran historias de los encuentros de un {{translate gender}} {{translate zodiac}} con un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Para cada dúo de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}, el universo crea una historia única.`,
        `El cosmos tiene su propia manera de celebrar el encuentro de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `En el libro de jugadas cósmico, el vínculo entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} es un capítulo por sí mismo.`,
        `Las mareas astrales cambian de manera intrigante con cada interacción entre un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} juntos trazan un rumbo único a través de los cielos del zodiaco.`,
        `Los misterios del zodiaco se profundizan con la unión de un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Cuando un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} se alinean, el universo lanza una mirada fascinada.`,
        `La danza cósmica encuentra un ritmo distintivo cuando un {{translate gender}} {{translate zodiac}} comparte espacio con un {{translate partnerGender}} {{translate partnerZodiac}}.`,
        `Un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} juntos son un testimonio de las infinitas maravillas del universo.`,
        `En la orquesta celestial, las notas tocadas por un {{translate gender}} {{translate zodiac}} y un {{translate partnerGender}} {{translate partnerZodiac}} crean una melodía propia.`,
      ],
    },
    input: {
      placeholder: {
        place: 'Ingrese la ciudad de nacimiento',
        date: {
          year: 'año',
          month: 'mes',
          day: 'día',
        },
      },
    },
    gender: {
      male: 'hombre',
      female: 'mujer',
    },
    general: {
      other: 'otro',
    },
    pinnedInfoButton: {
      help: 'Ayuda',
    },
    contactUs: {
      title: 'Contacte Con Nosotros',
      text:
        'Estaremos encantados de ayudarle a través del correo electrónico {{supportEmail}}.<br/>' +
        'También puede enviar sus preguntas y comentarios al ',
      supportCenter: 'Centro de asistencia',
    },
  },
};
